import React from "react";
import "../../../assets/css/courses.css";

const CourseDetails = (props) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUpBig animated">
      <div className="box">
        <div className={`our-services ${props.class}`}>
          <div className="d-flex justify-content-center">
            <div
              className="icon"
              style={{
                height: 80,
                width: 80,
                borderRadius: 45,
                backgroundImage:
                  props.class === "settings"
                    ? "linear-gradient(-45deg, #34b5bf 0%, #210c59 100%)"
                    : props.class === "speedup"
                    ? "linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%)"
                    : props.class === "privacy"
                    ? "linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%)"
                    : props.class === "backups"
                    ? "linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%)"
                    : props.class === "ssl"
                    ? "linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%)"
                    : `linear-gradient(-45deg, #27b88d 0%, #22dd73 100%)`,
                border: "solid 1px",
              }}
            >
              <img
                src={props.logo}
                alt="logo"
                width={"70%"}
                style={{ paddingTop: "10px" }}
              />
            </div>
          </div>
          <h4>{props.title}</h4>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
