import React, { useEffect } from "react";
import LandingPageLayout from "../../layout/landingPage/landingPageLayout";
import About from "./subComponent/about";
import Contact from "./subComponent/contact.js";
import Services from "./subComponent/services.js";
import Banner from "./subComponent/Banner.js";
import Courses from "./subComponent/courses.js";

export default function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <LandingPageLayout
        Children={
          <>
            <Banner />
            <About />
            <Services />
            <Courses />
            <Contact />
          </>
        }
      />
    </>
  );
}
