import React from "react";
import "../../../assets/css/courses.css";
import CourseDetails from "./courseDetails.js";
import { coursesDetails } from "../../../assets/utils/constant";

const Courses = () => {
  return (
    <section
      className="service-section pt_80 pb_90 wow fadeInLeftBig animated"
      id="courses"
    >
      <div>
        <div className="container-fluid mb-5">
          <div className="text-center mt-5">
            <div className="sec-title centred mb_60">
              <h6>Our Courses & Programming Tracks</h6>
            </div>
          </div>
          <div className="row ">
            {coursesDetails.map((course) => {
              return <CourseDetails {...course} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
