// import { Link } from "react-router-dom";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import BannerImage from "../../../assets/images/banner/banner-1.jpg";
// import BannerImage1 from "../../../assets/images/banner/banner-2.jpg";
// import BannerImage2 from "../../../assets/images/banner/banner-3.jpg";
// import ShapeImage1 from "../../../assets/images/shape/shape-1.png";
// import ShapeImage2 from "../../../assets/images/shape/shape-2.png";

// const swiperOptions = {
//   modules: [Autoplay, Pagination, Navigation],
//   slidesPerView: 1,
//   spaceBetween: 0,
//   autoplay: {
//     delay: 7000,
//     disableOnInteraction: false,
//   },
//   loop: true,

//   // Navigation
//   navigation: {
//     nextEl: ".h1n",
//     prevEl: ".h1p",
//   },

//   // Pagination
//   pagination: {
//     el: ".swiper-pagination",
//     clickable: true,
//   },
// };

// export default function Banner() {
//   return (
//     <>
//       <section className="banner-section p_relative">
//         <Swiper {...swiperOptions} className="banner-carousel">
//           <SwiperSlide className="slide-item p_relative">
//             <div
//               className="bg-layer"
//               style={{ backgroundImage: `url(${BannerImage})` }}
//             ></div>
//             <div className="pattern-layer">
//               <div
//                 className="pattern-1"
//                 style={{ backgroundImage: `url(${ShapeImage1})` }}
//               ></div>
//               <div
//                 className="pattern-2"
//                 style={{ backgroundImage: `url(${ShapeImage2})` }}
//               ></div>
//             </div>
//             <div className="auto-container">
//               <div className="content-box">
//                 <h2>
//                   Open our <span>Current</span> Account Online
//                 </h2>
//                 <p>
//                   This statistic is based on our average personal current
//                   account online opening time from the last 12 months.
//                 </p>
//                 <div className="btn-box">
//                   <Link href="/" className="theme-btn btn-one">
//                     Make an Appointment
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide className="slide-item p_relative">
//             <div
//               className="bg-layer"
//               style={{ backgroundImage: `url(${BannerImage1})` }}
//             ></div>
//             <div className="pattern-layer">
//               <div
//                 className="pattern-1"
//                 style={{ backgroundImage: `url(${ShapeImage1})` }}
//               ></div>
//               <div
//                 className="pattern-2"
//                 style={{ backgroundImage: `url(${ShapeImage2})` }}
//               ></div>
//             </div>
//             <div className="auto-container">
//               <div className="content-box">
//                 <h2>
//                   Open our <span>Current</span> Account Online
//                 </h2>
//                 <p>
//                   This statistic is based on our average personal current
//                   account online opening time from the last 12 months.
//                 </p>
//                 <div className="btn-box">
//                   <Link href="/" className="theme-btn btn-one">
//                     Make an Appointment
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide className="slide-item p_relative">
//             <div
//               className="bg-layer"
//               style={{ backgroundImage: `url(${BannerImage2})` }}
//             ></div>
//             <div className="pattern-layer">
//               <div
//                 className="pattern-1"
//                 style={{ backgroundImage: `url(${ShapeImage1})` }}
//               ></div>
//               <div
//                 className="pattern-2"
//                 style={{ backgroundImage: `url(${ShapeImage2})` }}
//               ></div>
//             </div>
//             <div className="auto-container">
//               <div className="content-box">
//                 <h2>
//                   {" "}
//                   Open our <span>Current</span> Account Online{" "}
//                 </h2>
//                 <p>
//                   This statistic is based on our average personal current
//                   account online opening time from the last 12 months.
//                 </p>
//                 <div className="btn-box">
//                   <Link href="/" className="theme-btn btn-one">
//                     Make an Appointment
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </SwiperSlide>

//           <div className="owl-nav">
//             <button type="button" className="owl-prev h1p">
//               <span className="icon-3"></span>
//             </button>
//             <button type="button" className="owl-next h1n">
//               <span className="icon-4"></span>
//             </button>
//           </div>
//         </Swiper>
//       </section>
//     </>
//   );
// }

import React from "react";
import logo from "../../../assets/images/logo.jpeg";
export default function Banner() {
  return (
    <section className="about-section pt_100 pb_50">
      <div
        className="container wow fadeInUpBig animated"
        data-wow-delay="00ms"
        data-wow-duration="1500ms"
      >
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center text-center">
            <div className="sec-title">
              <h4 style={{ color: "#2994eb" }}>
                WELCOME TO DEVELOPER'S POINT BARWANI
              </h4>
              <h1
                style={{
                  fontWeight: 800,
                  fontSize: 40,
                  lineHeight: 1.5,
                  letterSpacing: 0.9,
                }}
                className="pt-3"
              >
                Advance Your{" "}
                <span style={{ color: "#2994eb" }}> IT Career </span>&{" "}
                <span style={{ color: "#2994eb" }}>Elevate </span> Your
                Expertise
              </h1>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img src={logo} alt="" width={"100%"} height={"100%"} />
          </div>
        </div>
      </div>
    </section>
  );
}
