// export default function About() {
//   return (
//     <>
//       <section className="about-section pt_120 pb_120">
//         <div className="auto-container">
//           <div className="row clearfix">
//             <div className="col-lg-6 col-md-12 col-sm-12 content-column">
//               <div className="content_block_one">
//                 <div className="content-box ml_40">
//                   <div className="sec-title mb_20">
//                     <h6>WELCOME TO DEVELOPER'S POINT</h6>
//                     <h2>Complete Your IT Track & Boost Your Skills</h2>
//                   </div>
//                   <div className="text-box mb_40">
//                     <p>
//                       Developer's Point Solution is a professional training
//                       institute which provides trainings in all current
//                       technologies stacks from beginning to end with practically
//                       live environment.
//                     </p>
//                   </div>
//                   <div className="inner-box mb_45">
//                     <div className="single-item">
//                       <div className="icon-box">
//                         <i className="icon-11"></i>
//                       </div>
//                       <h3>Experienced Training Team</h3>
//                       <p>
//                         Benefit from guidance by a seasoned training team with
//                         extensive IT industry experience.
//                       </p>
//                     </div>
//                     <div className="single-item">
//                       <div className="icon-box">
//                         <i className="icon-11"></i>
//                       </div>
//                       <h3>Optimal Learning Environment</h3>
//                       <p>
//                         Experience personalized attention in small batch sizes,
//                         fostering maximum hands-on learning and minimal theory.
//                       </p>
//                     </div>
//                     <div className="single-item">
//                       <div className="icon-box">
//                         <i className="icon-11"></i>
//                       </div>
//                       <h3>Cutting-Edge Lab Facilities</h3>
//                       <p>
//                         Explore development and testing in our state-of-the-art
//                         lab equipped with the latest technology.
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-12 col-sm-12 image-column">
//               <div className="image_block_one">
//                 <div className="image-box pr_90 mr_40">
//                   <div
//                     className="image-shape"
//                     style={{ backgroundImage: `url(${shapeImage})` }}
//                   ></div>
//                   <figure className="image">
//                     <img src={image} alt="" />
//                   </figure>
//                   <div className="experience-box">
//                     <div className="inner">
//                       <h2>5</h2>
//                       <h6>Years of Experience</h6>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

import shapeImage from "../../../assets/images/shape/shape-3.png";
import image from "../../../assets/images/resource/developers_point_coaching.webp";
export default function About() {
  return (
    <>
      <section
        id="about"
        className="about-section pt_120 pb_120 wow fadeInRightBig animated"
      >
        <div className="pattern-layer rotate-me"></div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_one">
                <div className="image-box pr_90 mr_40">
                  <div
                    className="image-shape"
                    style={{
                      backgroundImage: `url(${shapeImage})`,
                    }}
                  ></div>
                  <figure className="image">
                    <img
                      src={image}
                      alt=""
                      style={{ filter: "brightness(0.6)" }}
                    />
                  </figure>
                  <div className="experience-box">
                    <div className="inner">
                      <h2>4</h2>
                      <h6>Years of Experience</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_one">
                <div className="content-box ml_40">
                  <div className="sec-title mb_20 mt_30">
                    <h6>About Us</h6>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      Developer'sPoint Solution is a premier training institute
                      offering comprehensive, hands-on training in the latest
                      technology stacks, from fundamentals to advanced levels,
                      in a real-world environment.
                    </p>
                  </div>
                  <div className="inner-box mb_45">
                    <div className="single-item">
                      <div className="icon-box">
                        <i className="icon-11"></i>
                      </div>
                      <h5>Expert-Led Training Programs</h5>
                      <p className="mt-1">
                        Gain insights from our seasoned trainers who bring
                        extensive experience from the IT industry.
                      </p>
                    </div>
                    <div className="single-item">
                      <div className="icon-box">
                        <i className="icon-11"></i>
                      </div>

                      <h5>Tailored Learning Experience</h5>
                      <p className="mt-1">
                        Enjoy individualized attention in small class sizes,
                        ensuring a balance of practical, hands-on learning and
                        essential theory.
                      </p>
                    </div>

                    <div className="single-item">
                      <div className="icon-box">
                        <i className="icon-11"></i>
                      </div>
                      <h5>Advanced Lab Facilities</h5>
                      <p className="mt-1">
                        Utilize our cutting-edge lab, fully equipped with the
                        latest technology for comprehensive development and
                        testing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
