import { Icon } from "@iconify/react/dist/iconify.js";
import image from "../../../assets/images/background/service-bg.jpg";
export default function Services() {
  return (
    <>
      <section className="service-section pt_80 pb_90 wow fadeInLeftBig animated">
        <div
          className="bg-layer"
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h6>Our Services</h6>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUpBig animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="shape"></div>
                  <div className="icon-box">
                    {/* <i className="icon-2"></i> */}

                    <Icon icon="icon-park-outline:code-computer" />
                  </div>
                  <h4>Programming Skills</h4>
                  <p style={{ fontSize: 17 }}>
                    Master essential programming languages and techniques for
                    effective software development.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUpBig animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="shape"></div>
                  <div className="icon-box">
                    <Icon icon="icon-park-outline:all-application" />
                  </div>
                  <h4> Application Development</h4>
                  <p style={{ fontSize: 18 }}>
                    Learn to build robust applications from scratch using modern
                    development frameworks.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUpBig animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="shape"></div>
                  <div className="icon-box">
                    <Icon icon="solar:database-outline" />
                  </div>
                  <h4>Database</h4>
                  <p style={{ fontSize: 18 }}>
                    Gain expertise in designing, managing, and optimizing
                    databases for efficient data storage and retrieval.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUpBig animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="shape"></div>
                  <div className="icon-box">
                    <Icon icon="fluent-mdl2:website" />
                  </div>
                  <h4>WebSite Design</h4>
                  <p style={{ fontSize: 17 }}>
                    Develop professional web design skills to create visually
                    appealing and user-friendly websites.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUpBig animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="shape"></div>
                  <div className="icon-box">
                    <Icon icon="carbon:machine-learning" />
                  </div>
                  <h4> AI and Machine Learning</h4>
                  <p style={{ fontSize: 17 }}>
                    Explore the fundamentals of AI and machine learning to
                    develop intelligent systems and solutions.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 service-block">
              <div
                className="service-block-one wow fadeInUpBig animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="shape"></div>
                  <div className="icon-box">
                    <Icon icon="grommet-icons:cloud-software" />
                  </div>
                  <h4> Software Testing</h4>
                  <p style={{ fontSize: 17 }}>
                    Acquire critical software testing skills to ensure the
                    quality and reliability of software products.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
